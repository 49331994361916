import { useSearchParams } from 'react-router-dom';

type ParsingRules = {
  type: 'string' | 'number' | 'boolean';
  required?: boolean;
  defaultValue?: string | number | boolean;
};

type ParamParsingRules<T> = {
  [key in keyof T]: ParsingRules;
};

export function useParsedSearchParams<T>(parsingRules: ParamParsingRules<T>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedSearchParams = {} as {
    [K in keyof T]: T[K];
  };
  const entries = Object.entries(parsingRules) as [keyof T, ParsingRules][];

  for (const [key, { type, defaultValue }] of entries) {
    const value = searchParams.get(key as string);

    switch (type) {
      case 'string':
        // @ts-ignore
        parsedSearchParams[key] = value ?? defaultValue;
        break;

      case 'number':
        // @ts-ignore
        parsedSearchParams[key] = Number(value ?? defaultValue);
        break;

      case 'boolean':
        // @ts-ignore
        parsedSearchParams[key] = (value ?? defaultValue) === 'true';
        break;
    }
  }

  return {
    params: parsedSearchParams,
    updateSearchParams(params: Partial<T>) {
      const newSearchParams = new URLSearchParams(searchParams);

      for (const [key, value] of Object.entries(params)) {
        // @ts-ignore
        newSearchParams.set(key, value.toString());
      }

      setSearchParams(newSearchParams);
    },
  };
}
