import { ONE_MINUTE_IN_MS } from '@allurion/utils';
import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { ActivePatient } from 'src/domain/patient/Patients';

export const ACTIVE_PATIENTS = (clinicId: string) => `/clinics/${clinicId}/active-patients`;

export function useClinicActivePatients(
  clinicId: string,
  {
    page,
    pageSize,
    patientFilter,
    sortBy,
  }: { patientFilter?: string; page?: number; pageSize?: number; sortBy?: string } = {}
) {
  const query = {
    search: patientFilter,
    page,
    pageSize,
    sortBy,
  };

  const url = buildUrl(ACTIVE_PATIENTS(clinicId.toString()), query);
  const { data, error, isValidating, isLoading } = useSWR(
    url,
    (_url) => InsightsApi.get<{ activePatients: ActivePatient[]; totalPages: number }>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
      dedupingInterval: ONE_MINUTE_IN_MS,
    }
  );

  return {
    activePatients: data.activePatients ?? [],
    totalPages: data.totalPages ?? 0,
    error,
    isLoading: isValidating || isLoading,
  };
}
