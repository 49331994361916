import { ChevronIcon } from '@allurion/ui';
import { useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton, TrackedIconButton } from 'src/analytics/TrackedUI';

export function Pagination({
  page,
  setPage,
  lastPage,
  setPageSize,
  defaultPageSize,
}: {
  page: number;
  setPage: (page: number) => void;
  defaultPageSize: number;
  lastPage: number;
  pageSize: number;
  setPageSize: (page: number) => void;
  pageSizeLabel: string;
}) {
  const intl = useIntl();
  const [viewAll, setViewAll] = useState(false);

  const nextPage = () => {
    if (page >= lastPage) {
      return;
    }

    setPage(page + 1);
  };

  const prevPage = () => {
    if (page <= 1) {
      return;
    }

    setPage(page - 1);
  };

  const toggleViewAll = () => {
    const nextViewAll = !viewAll;

    setViewAll(nextViewAll);
    if (nextViewAll) {
      setPageSize(Number.MAX_SAFE_INTEGER);
      setPage(1);
    } else {
      setPageSize(defaultPageSize);
    }
  };

  return (
    <PaginationContainer>
      {viewAll ? (
        <div className="page-sizes">
          <TrackedButton
            label={intl.formatMessage({
              id: 'manage-patients-table.hide-all',
              defaultMessage: 'Hide All',
            })}
            trackLabel="hide-all"
            onClick={toggleViewAll}
            rightIcon={<ChevronIcon direction="up" />}
            size="xs"
            variant="secondary"
          />
        </div>
      ) : (
        <>
          <div className="page-sizes">
            <TrackedButton
              label={intl.formatMessage({
                id: 'manage-patients-table.view-all',
                defaultMessage: 'View All',
              })}
              trackLabel="view-all"
              onClick={toggleViewAll}
              rightIcon={<ChevronIcon direction="down" />}
              size="xs"
              variant="secondary"
            />
          </div>

          <div className="page-selector">
            <span className="page-selector-label">
              {intl.formatMessage(
                {
                  id: 'manage-patients.table.page-index',
                  defaultMessage: 'Page {currentPage} of {totalPages}',
                },
                { currentPage: page, totalPages: lastPage || 1 }
              )}
            </span>

            <TrackedIconButton
              onClick={prevPage}
              disabled={page === 1}
              icon={<ChevronIcon direction="left" />}
              size="xs"
              variant="secondary"
              trackLabel="previous-page"
            />
            <TrackedIconButton
              onClick={nextPage}
              disabled={page >= lastPage}
              icon={<ChevronIcon direction="right" />}
              size="xs"
              variant="secondary"
              trackLabel="next-page"
            />
          </div>
        </>
      )}
    </PaginationContainer>
  );
}

const PaginationContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 13px;
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-weight: 500;

  button.MuiButton-text {
    padding: 12px 16px;
    font-size: 13px;
    line-height: 13px;
    min-width: unset;
    width: unset;
    border-radius: 2px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .page-sizes {
    flex-shrink: 0;
    margin-bottom: 12px;

    .page-sizes-label {
      margin-right: 6px;
    }
  }

  .page-selector {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 12px;

    .page-selector-label {
      margin-right: 16px;
    }

    button {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
